
import { defineComponent } from 'vue';
import Experience from '@/components/Experience.vue';
import experiencesData from '@/assets/data/experiences.json';

export default defineComponent({
  name: 'Experiences',
  components: {
    Experience
  },
  data() {
    return {
      experiences: experiencesData
    };
  },
  methods: {
    normalize(str: string): string {
      // Uppercase first letter
      let normalized = str.charAt(0).toUpperCase() + str.slice(1);
      // Remove dashes
      normalized = normalized.replaceAll('-', ' ');
      return normalized;
    }
  }
});
